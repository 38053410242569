import { Pipe, PipeTransform } from '@angular/core';
import { SelfieRecommendationRules } from '../../state/models/selfie-recommendation-rules';

@Pipe({
  name: 'selfieRecommendationRulesFormat',
})
export class SelfieRecommendationRulesFormatPipe implements PipeTransform {
  constructor() {
  }

  transform(
    value: SelfieRecommendationRules,
    type: string,
  ): string {
    if (!value) {
      return '';
    }

    if (type === 'FROM') {
      const { exam, specialty } = value;
      if (exam) {
        const { name } = exam;
        return `Exame: ${ name }`;
      } else if (specialty) {
        const { name } = specialty;
        return `Especialidade: ${ name }`;
      }
    }

    if (type === 'TO') {
      const { toExam, toSpecialty } = value;
      if (toExam) {
        const { name } = toExam;
        return `Exame: ${ name }`;
      } else if (toSpecialty) {
        const { name } = toSpecialty;
        return `Especialidade: ${ name }`;
      }
    }
    return '';
  }
}
