import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';

@Injectable()
export class SummaryStatusGuard implements CanActivate {
  constructor(
    private personEntityService: PersonEntityService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { personId } = route.queryParams;
    if (personId) {
      return this.personEntityService.crmPersons(personId).pipe(
        switchMap(crmPerson => {
          const { patientAppDigitalStatus } = crmPerson;
          if (patientAppDigitalStatus === 'FINISHED') {
            return of(true);
          } else {
            this.redirectTo('/');
          }
          return of(true);
        })
      );
    }
    return of(true);
  }

  redirectTo(path: string, queryParams = {}) {
    this.router.navigate([path], {
      queryParams
    });
  }
}
