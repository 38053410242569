import { Injectable } from '@angular/core';
import { CanActivate, } from '@angular/router';
import { OrganizationService } from '../services/organization.service';
import { Cycles } from '../../state/models/cycles';

@Injectable()
export class CycleHasIncentiveGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
  ) {
  }
  canActivate() {
    const cycle: Cycles = this.organizationService.cycle;
    return cycle.hasIncentive;
  }
}
