export var DEFAULT_EVENT_PREFIX = '[Amplitude]';
export var DEFAULT_PAGE_VIEW_EVENT = "".concat(DEFAULT_EVENT_PREFIX, " Page Viewed");
export var DEFAULT_FORM_START_EVENT = "".concat(DEFAULT_EVENT_PREFIX, " Form Started");
export var DEFAULT_FORM_SUBMIT_EVENT = "".concat(DEFAULT_EVENT_PREFIX, " Form Submitted");
export var DEFAULT_FILE_DOWNLOAD_EVENT = "".concat(DEFAULT_EVENT_PREFIX, " File Downloaded");
export var DEFAULT_SESSION_START_EVENT = 'session_start';
export var DEFAULT_SESSION_END_EVENT = 'session_end';
export var FILE_EXTENSION = "".concat(DEFAULT_EVENT_PREFIX, " File Extension");
export var FILE_NAME = "".concat(DEFAULT_EVENT_PREFIX, " File Name");
export var LINK_ID = "".concat(DEFAULT_EVENT_PREFIX, " Link ID");
export var LINK_TEXT = "".concat(DEFAULT_EVENT_PREFIX, " Link Text");
export var LINK_URL = "".concat(DEFAULT_EVENT_PREFIX, " Link URL");
export var FORM_ID = "".concat(DEFAULT_EVENT_PREFIX, " Form ID");
export var FORM_NAME = "".concat(DEFAULT_EVENT_PREFIX, " Form Name");
export var FORM_DESTINATION = "".concat(DEFAULT_EVENT_PREFIX, " Form Destination");
export var DEFAULT_IDENTITY_STORAGE = 'cookie';
export var DEFAULT_SERVER_ZONE = 'US';
