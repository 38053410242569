import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { map } from 'rxjs/operators';
import { Organization, OrganizationExports, OrganizationImports } from '../models/organization';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationEntityService extends BaseEntityService<Organization> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'organization', plural: 'organizations' },
      routerParamsService
    );
    this.nameId = 'organizationId';
  }

  getWhitelabel(nameUrlSafe: string) {
    const body = { organization: { nameUrlSafe } };
    return this.httpClient
      .post([this.entityUrl, 'whitelabel'].join('/'), body)
      .pipe(map((response: any) => response[this.singularEntityName]));
  }

  save(dataToSave): Observable<Organization> {
    const nDateToSave = { ...dataToSave };
    Object.keys(nDateToSave).forEach(key => {
      if (!nDateToSave[key] && !dataToSave.id && nDateToSave[key] !== false) {
        delete nDateToSave[key];
      } else if (!nDateToSave[key] && nDateToSave[key] !== false) {
        nDateToSave[key] = null;
      }
    });
    const body = {
      ...nDateToSave,
    };
    if (body.id) {
      return this.update(body);
    }
    return this.add(body);
  }


  organizationExports(organizationId: number): Observable<OrganizationExports> {
    const body = {
      organizationExport: {
        organization: {
          id: organizationId,
        },
      },
    };
    return this.httpClient
      .post([environment.apiUrl, 'organization-exports'].join('/'), body)
      .pipe(map((response: any) => response?.organizationExport));
  }

  organizationManagerExports(organizationId: number): Observable<OrganizationExports> {
    const body = {
      managerExport: {
        organization: {
          id: organizationId,
        },
      },
    };
    return this.httpClient
      .post([environment.apiUrl, 'manager-exports'].join('/'), body)
      .pipe(map((response: any) => response?.managerExport));
  }

  organizationImports(values: any): Observable<OrganizationImports[]> {
    const body = {
      organizationImport: {
        ...values
      }
    };
    return this.httpClient
      .post([environment.apiUrl, 'organization-imports'].join('/'), body)
      .pipe(map((response: any) => response?.organizationImports));
  }
}
