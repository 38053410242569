import { Component, Input, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'shared-image-viewer',
  templateUrl: './shared-image-viewer.component.html',
  styleUrls: ['./shared-image-viewer.component.scss']
})
export class SharedImageViewerComponent implements AfterViewInit {
  @Input() imageUrl: string = '';
  @ViewChild('imageElement') imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainer') imageContainer!: ElementRef<HTMLDivElement>;

  private rotation: number = 0;
  private scale: number = 1;

  constructor(private renderer: Renderer2) {}

  get transform() {
    return `rotate(${this.rotation}deg) scale(${this.scale})`;
  }

  ngAfterViewInit() {
    this.adjustImageSize();
  }

  rotate() {
    this.rotation = (this.rotation + 90) % 360;
    this.adjustImageSize();
  }

  zoomIn() {
    this.scale += 0.1;
    this.adjustImageSize();
  }

  zoomOut() {
    if (this.scale > 0.1) {
      this.scale -= 0.1;
    }
    this.adjustImageSize();
  }

  reset() {
    this.rotation = 0;
    this.scale = 1;
    this.imageElement.nativeElement.style.transform = this.transform;
    this.imageElement.nativeElement.style.width = '';
    this.imageElement.nativeElement.style.height = '';
    this.adjustImageSize();
  }

  private adjustImageSize() {
    const image = this.imageElement.nativeElement;
    const container = this.imageContainer.nativeElement;
    image.style.transform = this.transform;

    const imageNaturalWidth = image.naturalWidth;
    const imageNaturalHeight = image.naturalHeight;

    if (this.rotation % 180 !== 0) {
      // Swap width and height
      container.style.width = `${imageNaturalHeight * this.scale}px`;
      container.style.height = `${imageNaturalWidth * this.scale}px`;
    } else {
      container.style.width = `${imageNaturalWidth * this.scale}px`;
      container.style.height = `${imageNaturalHeight * this.scale}px`;
    }
  }
}
