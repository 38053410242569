import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-flex',
  template: `
    <div
      class="flex-form-content w-100"
      [flexGap]="calculateGap(props)"
      [marLayout]="props.fxLayout || 'row'"
    >
      @for (f of field.fieldGroup; track $index) {
        <div class="w-100">
          <formly-field
            [field]="defaultField(f)">
          </formly-field>
        </div>
      }
    </div>
    <div>
    </div>
  `,
})
export class FlexLayoutType extends FieldType implements OnInit {

  ngOnInit(): void {
    console.log();
    this.field.fieldGroup = this.field.fieldGroup.map(fg => {
      return Object.assign(fg, { props: { appearance: 'outline', ...fg.props } });
    });
  }

  public calculateGap(props): number {
    const { fxLayoutGap = 24, fxLayout } = props;
    if (fxLayout === 'column' || this._isMobile()) {
      return 0;
    }
    return fxLayoutGap as any;
  }

  public calculateGapXs(props) {
    const { fxLayoutGapXs = '8px', fxLayoutXs } = props;
    if (fxLayoutXs === 'column' || this._isMobile()) {
      return '0';
    }
    return fxLayoutGapXs;
  }

  public defaultField(field) {
    return field;
  }

  private _isMobile(): boolean {
    return window.innerWidth <= 600;
  }


}
