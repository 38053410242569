import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-code-type',
  template: `
    <label>{{ props.label }}</label>
    <code-input
      id="codeInput"
      language="{{ props.codeType ?? 'HTML' }}"
      placeholder="Digite o código aqui"
      template="syntax-highlighted"
      (keyup)="setNewValue($event)">
    </code-input>
    <mat-form-field class="hide-form" appearance="outline">
                <textarea
                  matInput
                  [placeholder]="props.placeholder"
                  [formControl]="formControl" [formlyAttributes]="field"
                ></textarea>
    </mat-form-field>
  `,
  styleUrls: ['./formly-field-code-type.component.scss'],
})
export class FormlyFieldCodeTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    // @ts-ignore
    document.getElementById('codeInput').value = this.formControl?.value;
  }

  setNewValue(value: any) {
    this.formControl.setValue(value?.target?.value);
  }
}
