import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  defaultTitle = 'Gestor | Mar Saúde';

  constructor(
    private organizationService: OrganizationService,
    private titleService: Title, private metaTag: Meta
  ) {
  }

  setTitle(title?: string): void {
    const organization = this.organizationService.getOrganization();
    if (organization && organization.name) {
      this.defaultTitle = organization.name;
    }

    const pageTitle = `${ title ? title + ' | ' : '' }${ this.defaultTitle }`;
    this.titleService.setTitle(pageTitle);
    this.metaTag.updateTag({
      name: 'title',
      content: pageTitle,
    });
    this.metaTag.updateTag({
      name: 'og:title',
      content: pageTitle,
    });
  }
}
