import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { FormSubmission } from '../models/form.model';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormSubmissionsEntityService extends BaseEntityService<FormSubmission> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'formSubmission', plural: 'formSubmissions', url: 'form-submissions' },
      routerParamsService
    );
    this.nameId = 'formSubmissionId';
  }
}
