import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DataConfirm } from './confirm.service';

@Component({
  selector: 'confirm',
  styleUrls: ['./confirm.component.scss'],
  template: `
    <h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16" [innerHTML]="data.message"></div>
    <div mat-dialog-actions>
      <button
        color="accent"
        mat-dialog-close
        mat-raised-button
        type="button"
        *ngIf="data.buttonCancel.show"
        (click)="dialogRef.close(false)"
      >
        {{ data.buttonCancel.label }}
      </button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        *ngIf="data.buttonConfirm.show"
        (click)="dialogRef.close(true)"
      >
        {{ data.buttonConfirm.label }}
      </button>
    </div>
  `,
})
export class ConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataConfirm,
  ) {
  }
}
