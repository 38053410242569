import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  changeFavicon(url: string): void {
    const link: HTMLLinkElement = this.renderer.selectRootElement('link[rel="icon"]', true) as HTMLLinkElement;
    const href = `${ url }?${ new Date().getTime() }`; // Adiciona timestamp para evitar cache
    if (link) {
      this.renderer.setAttribute(link, 'href', href);
    } else {
      const head = this.renderer.selectRootElement('head');
      const newLink = this.renderer.createElement('link');
      this.renderer.setAttribute(newLink, 'rel', 'icon');
      this.renderer.setAttribute(newLink, 'href', href);
      this.renderer.appendChild(head, newLink);
    }
  }
}
