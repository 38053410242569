codeInput.plugins.Autocomplete = class extends codeInput.Plugin {
  constructor(a) {
    super([]), this.updatePopupCallback = a;
  }
  updatePopup(a, b) {
    let c = a.textareaElement,
      d = this.getCaretCoordinates(a, c, c.selectionEnd, b),
      e = a.querySelector(".code-input_autocomplete_popup");
    e.style.top = d.top + "px", e.style.left = d.left + "px", b || this.updatePopupCallback(e, c, c.selectionEnd);
  }
  afterElementsAdded(a) {
    let b = document.createElement("div");
    b.classList.add("code-input_autocomplete_popup"), a.appendChild(b);
    let c = document.createElement("pre");
    if (c.setAttribute("aria-hidden", "true"), a.template.preElementStyled) c.classList.add("code-input_autocomplete_testpos"), a.appendChild(c);else {
      let b = document.createElement("code");
      b.classList.add("code-input_autocomplete_testpos"), c.appendChild(b), a.appendChild(c);
    }
    let d = a.textareaElement;
    d.addEventListener("input", () => {
      this.updatePopup(a, !1);
    }), d.addEventListener("click", () => {
      this.updatePopup(a, !1);
    });
  }
  getCaretCoordinates(a, b, c, d) {
    let e;
    if (d) {
      let d = a.querySelector(".code-input_autocomplete_testpos").querySelectorAll("span");
      if (2 > d.length) return this.getCaretCoordinates(a, b, c, !1);
      e = d[1];
    } else {
      let d = a.querySelector(".code-input_autocomplete_testpos"),
        f = document.createElement("span");
      for (f.textContent = b.value.substring(0, c), e = document.createElement("span"), e.textContent = "."; d.firstChild;) d.removeChild(d.firstChild);
      d.appendChild(f), d.appendChild(e);
    }
    return {
      top: e.offsetTop - b.scrollTop,
      left: e.offsetLeft - b.scrollLeft
    };
  }
  updatePopupCallback = function () {};
};