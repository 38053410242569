import { FormControl } from '@angular/forms';


export function ValidatorEmail(c: FormControl) {
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  const emailValid = reg.test(c.value);
  if (!c.value) {
    return null;
  }

  if (!emailValid) {
    return { email: true };
  }

  return null;
}


//
// export class ValidatorEmaisl {
//   static validator(c: FormControl) {
//     const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
//     const emailValid = reg.test(c.value);
//     if (!c.value) {
//       return null;
//     }
//
//     if (!emailValid) {
//       return { validateEmail: true };
//     }
//
//     return null;
//   }
//
//   static validateString(value: string) {
//     const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
//     return reg.test(value);
//   }
// }
