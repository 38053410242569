<div class="wrap">
  <div class="image-container" #imageContainer >
    <img #imageElement [src]="imageUrl" [style.transform]="transform" alt="Image"/>
  </div>
</div>
<div class="controls">
  <button mat-raised-button color="warm" (click)="rotate()">Rotacionar <mat-icon>screen_rotation</mat-icon></button>
  <button mat-raised-button color="warm" (click)="zoomIn()">Zoom <mat-icon>zoom_in</mat-icon></button>
  <button mat-raised-button color="warm" (click)="zoomOut()">Zoom <mat-icon>zoom_out</mat-icon></button>
  <button mat-raised-button color="warm" (click)="reset()">Resetar <mat-icon>history</mat-icon></button>
</div>
