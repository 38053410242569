import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { ConfirmComponent } from './confirm.component';

@Injectable()
export class ConfirmService {
  constructor(private dialog: MatDialog) {
  }

  public confirm(data?: DataConfirm): Observable<boolean> {
    const nData = {
      title: data?.title || 'Confirmar',
      message: data?.message || 'Você tem certeza?',
      buttonConfirm: data?.buttonConfirm ?? {
        label: 'Ok',
        show: true
      },
      buttonCancel: data?.buttonCancel || {
        label: 'Cancelar',
        show: true
      }
    };
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.dialog.open(ConfirmComponent, {
      width: '500px',
      disableClose: data.disableClose ?? true,
      data: nData
    });
    return dialogRef.afterClosed();
  }
}

export interface DataConfirm {
  disableClose?: boolean;
  title: string;
  message?: string;
  buttonConfirm?: {
    show: boolean;
    label: string;
  };
  buttonCancel?: {
    show: boolean;
    label: string;
  };
}
