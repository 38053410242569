import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

import hljs from 'highlight.js';
import '@webcoder49/code-input/plugins/indent.js';
import '@webcoder49/code-input/plugins/autocomplete.min.js';
import '@webcoder49/code-input/plugins/autodetect.min.js';


if (environment.production) {
  Sentry.init({
    release: 'admin@' + environment.version,
    environment: environment.env,
    dsn: 'https://ffab88861b60580fde143472d2091610@o4506711249649664.ingest.us.sentry.io/4507527758741504',
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      environment.loginRedirect
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


try {
  // @ts-ignore
  console.log(codeInput);
  // @ts-ignore
  codeInput.registerTemplate('syntax-highlighted',
// @ts-ignore
    codeInput.templates.hljs(
      hljs,
      [
        // You can add or remove plugins in this list from https://github.com/WebCoder49/code-input/blob/main/plugins/README.md.
        // All plugins used must be imported above.
        // @ts-ignore
        new codeInput.plugins.Autodetect(),
        // @ts-ignore
        new codeInput.plugins.Indent(true, 2), // Allow Tab-key indentation, with 2 spaces indentation
      ]
    )
  );
} catch (e) {
  console.error(e);
}
