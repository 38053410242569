import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';
import { FaviconService } from '../services/favicon.service';

@Injectable()
export class OrganizationGuard implements CanActivate {
  constructor(
    private faviconService: FaviconService,
    private organizationService: OrganizationService,
    private organizationEntityService: OrganizationEntityService,
    private router: Router, private jwtAuth: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { nameUrlSafe } = route.queryParams;
    if (!nameUrlSafe) {
      return of(true);
    }
    return this.organizationEntityService.getWhitelabel(nameUrlSafe).pipe(
      map(organization => {
        this.faviconService.changeFavicon(organization.favicon);
        return this.organizationService.setOrganization(organization);
      })
    );
  }

}


