import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { JwtAuthService } from '../services/auth/jwt-auth.service';

@Injectable()
export class PatientStatusGuard implements CanActivate {
  constructor(
    private jwtAuthService: JwtAuthService,
    private personEntityService: PersonEntityService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { personId } = route.queryParams;
    const { patientAppDigitalStatus: patientAppDigitalStatusUrl } = route.params;
    const user = this.jwtAuthService.getUser();
    if (personId || user.id) {
      return this.personEntityService.crmPersons(personId || user.id).pipe(
        switchMap(crmPerson => {
          const { patientAppDigitalStatus, id: crmPersonId } = crmPerson;
          if (patientAppDigitalStatus === 'FINISHED') {
            this.redirectTo(`/paciente/devolutiva/${ personId || user.id }`);
            return of(true);
          } else {
            if (patientAppDigitalStatus !== patientAppDigitalStatusUrl) {
              this.redirectTo('', { patientAppDigitalStatus, personId, crmPersonId });
            }
          }
          return of(true);
        })
      );
    }
    return of(true);
  }

  redirectTo(path: string, queryParams = {}) {
    this.router.navigate([path], {
      queryParams
    });
  }
}
