import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReportIncentiveParameter } from '../../state/models/reports';

export type MetabaseNames = 44 | 45 | 47 | 48;

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private metabaseConfig = {
    44: { id: 44, title: 'Dados gerais', icon: 'dashboard_outlined', url: 'dados-gerais', minSubscriptions: 0 },
  };

  constructor(private _httpClient: HttpClient) {
  }

  private getMetabaseProperty(id: MetabaseNames, property: keyof typeof this.metabaseConfig[44]): string | number {
    return this.metabaseConfig[id][property];
  }

  public getReportId(name: string): string {
    return `METABASE_${ this.getConfigByUrl(name) }`;
  }

  public getReportTitle(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'title') as string;
  }

  public getReportIcon(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'icon') as string;
  }

  public getReportMinSubscription(name: MetabaseNames): number {
    return this.getMetabaseProperty(name, 'minSubscriptions') as number;
  }

  public getReportUrlId(name: MetabaseNames): string {
    return this.getMetabaseProperty(name, 'url') as string;
  }

  public getReportUrl(name: MetabaseNames): string {
    return `/organizacoes/organizationId/estatisticas/${ this.getReportUrlId(name) }`;
  }

  public getConfigByUrl(url: string) {
    for (const key in this.metabaseConfig) {
      if (this.metabaseConfig[key].url === url) {
        return this.metabaseConfig[key].id;
      }
    }
    return null; // Retorna null se a URL não for encontrada
  }

  getListAllReportsOptions() {
    return Object.keys(this.metabaseConfig).map((key: any) => {
      return {
        value: this.getMetabaseProperty(key, 'url'),
        label: this.getReportTitle(key),
        icon: this.getReportIcon(key),
        minSubscriptions: this.getReportMinSubscription(key),
      };
    });
  }

  getReportByOrganizationIdReportName(organizationId: any, metabaseIdName: string): Observable<any> {
    const body = {
      report: {
        reportTemplate: {
          name: this.getReportId(metabaseIdName),
        },
        organization: {
          id: organizationId,
        },
      },
    };
    return this.getReport(body);
  }

  getReportIncentive(organizationId: number, cycleId: number): Observable<ReportIncentiveParameter[]> {
    const body = {
      report: {
        reportTemplate: {
          name: 'INCENTIVE_PARAMETER',
        },
        organization: {
          id: organizationId,
        },
        reportParams: { cycleId }
      },
    };
    return this.getReport(body).pipe(
      map(response => response?.results)
    );
  }

  getReport(body: any): Observable<any> {
    const _baseUrlReports = `${ environment.apiUrl }/reports`;
    return this._httpClient.post(_baseUrlReports, body).pipe(
      map((response: any) => response?.report)
    );
  }
}
