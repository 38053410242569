<label *ngIf="label"> <br/> {{ label }}:</label>
<div class="center" style="border-radius: 30px 30px 0 0; position: relative;
    overflow: hidden;">
  <ngx-file-drop (onFileDrop)="dropped($event)" (onFileLeave)="fileLeave($event)"
                 (onFileOver)="fileOver($event)" dropZoneLabel="Solte o arquivo aqui">
    <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
      <button (click)="openFileSelector()" [color]="'primary'"
              mat-stroked-button type="button">
        <span marLayout="row" marLayoutAlign="center center"
              [flexGap]="4">
          <span [innerHTML]="loading? 'Aguarde...': titleButton"></span>
          <mat-icon *ngIf="showRemoveButton" matTooltip="Arquivo enviado" style="color: darkslategrey">check</mat-icon>
          </span>
      </button>
      <button (click)="removeFile();$event.stopPropagation();" *ngIf="showRemoveButton" color="warn" mat-icon-button
              type="button">
        <mat-icon matTooltip="Remover arquivo">delete</mat-icon>
      </button>
    </ng-template>
  </ngx-file-drop>
</div>
