import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from './excerpt.pipe';
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { StringFormatPipe } from './string-format.pipe';
import { DocumentPipe } from './document.pipe';
import { DatePipe } from './date.pipe';
import { PhonePipe } from './phone.pipe';
import { TranslationPipe } from './translation.pipe';
import { NumeralPipe } from './numeral.pipe';
import { IsEqualsPipe } from './isEquals.pipe';
import { SafePipe } from './safe.pipe';
import { AgePipe } from './age.pipe';
import { SelfieRecommendationRulesFormatPipe } from './selfie-recommendation-rules-format.pipe';
import { PersonReturnIconPipe } from './person-return-icon.pipe';

const pipes = [
  PersonReturnIconPipe,
  SelfieRecommendationRulesFormatPipe,
  AgePipe,
  SafePipe,
  IsEqualsPipe,
  NumeralPipe,
  TranslationPipe,
  PhonePipe,
  DatePipe,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  StringFormatPipe,
  DocumentPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class SharedPipesModule {
}
