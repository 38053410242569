import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-masked-type',
  template: `
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>{{ props.label }}</mat-label>
      <div marLayout="row" [flexGap]="8">
        <span *ngIf="props.addonLeft" matTextSuffix>{{ props.addonLeft }}</span>
        <input
          matInput
          [type]="typeInput"
          [formControl]="formControl" [formlyAttributes]="field"
          [imask]="mask"
          [unmask]="unmask"
        />
        <span *ngIf="props.addonRight" matTextPrefix>{{props.addonRight }}</span>
      </div>
    </mat-form-field>
  `,
})
export class FormlyFieldMaskedTypeComponent extends FieldType<FieldTypeConfig> {
  constructor() {
    super();
  }

  get typeInput() {
    if (this.props.mask === 'PHONE') {
      return 'tel';
    }
    return 'text';
  }

  get unmask() {
    if (this.props.mask === 'TIME') {
      return false;
    }
    if (this.props.mask === 'DATE') {
      return false;
    }
    return true;
  }

  get mask(): any {
    if (this.props.mask === 'DOCUMENT') {
      return [
        {
          mask: '000.000.000-00',  // CPF
        },
        {
          mask: '00.000.000/0000-00',  // CNPJ
          regex: '^\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}\\-\\d{2}$',
          lazy: false,
        },
        {
          mask: '000.000.000/000-00',  // CAEPF
          regex: '^\\d{3}\\.\\d{3}\\.\\d{3}/\\d{3}\\-\\d{2}$',
          lazy: false,
        }
      ]
    }

    if (this.props.mask === 'CAEPF') {
      return {
        mask: '000.000.000/000-00',  // CAEPF
        // regex: '^\\d{3}\\.\\d{3}\\.\\d{3}/\\d{3}\\-\\d{2}$',
      };
    }

    if (this.props.mask === 'CNPJ') {
      return {
        mask: '00.000.000/0000-00',  // CNPJ
        // regex: '^\\d{2}\\.\\d{3}\\.\\d{3}/\\d{4}\\-\\d{2}$',
      };
    }

    if (this.props.mask === 'CPF') {
      return {
        mask: '000.000.000-00',  // CPF
        // regex: '^\\d{3}\\.\\d{3}\\.\\d{3}\\-\\d{2}$',
      };
    }

    if (this.props.mask === 'DATE') {
      return { mask: '00/00/0000' };
    }

    if (this.props.mask === 'TIME') {
      return { mask: '00:00:00' };
    }

    if (this.props.mask === 'NUMBER') {
      return {
        mask: Number,
        scale: 2,
        thousandsSeparator: '.',
        padFractionalZeros: false,
        normalizeZeros: true,
        radix: ',',
        mapToRadix: ['.'],
        autofix: true,
      };
    }

    if (this.props.mask === 'OTP') {
      return { mask: '00 00 00' };
    }

    if (this.props.mask === 'PHONE') {
      return { mask: '(00)00000-0000' };
    }

    return this.props.mask || '(00)00000-0000';
  }

}
