/*
 Language: Gherkin
 Author: Sam Pikesley (@pikesley) <sam.pikesley@theodi.org>
 Description: Gherkin is the format for cucumber specifications. It is a domain specific language which helps you to describe business behavior without the need to go into detail of implementation.
 Website: https://cucumber.io/docs/gherkin/
 */

function gherkin(hljs) {
  return {
    name: 'Gherkin',
    aliases: ['feature'],
    keywords: 'Feature Background Ability Business\ Need Scenario Scenarios Scenario\ Outline Scenario\ Template Examples Given And Then But When',
    contains: [{
      className: 'symbol',
      begin: '\\*',
      relevance: 0
    }, {
      className: 'meta',
      begin: '@[^@\\s]+'
    }, {
      begin: '\\|',
      end: '\\|\\w*$',
      contains: [{
        className: 'string',
        begin: '[^|]+'
      }]
    }, {
      className: 'variable',
      begin: '<',
      end: '>'
    }, hljs.HASH_COMMENT_MODE, {
      className: 'string',
      begin: '"""',
      end: '"""'
    }, hljs.QUOTE_STRING_MODE]
  };
}
module.exports = gherkin;