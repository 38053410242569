/*
Language: WebAssembly
Website: https://webassembly.org
Description:  Wasm is designed as a portable compilation target for programming languages, enabling deployment on the web for client and server applications.
Category: web, common
Audit: 2020
*/

/** @type LanguageFn */
function wasm(hljs) {
  hljs.regex;
  const BLOCK_COMMENT = hljs.COMMENT(/\(;/, /;\)/);
  BLOCK_COMMENT.contains.push("self");
  const LINE_COMMENT = hljs.COMMENT(/;;/, /$/);
  const KWS = ["anyfunc", "block", "br", "br_if", "br_table", "call", "call_indirect", "data", "drop", "elem", "else", "end", "export", "func", "global.get", "global.set", "local.get", "local.set", "local.tee", "get_global", "get_local", "global", "if", "import", "local", "loop", "memory", "memory.grow", "memory.size", "module", "mut", "nop", "offset", "param", "result", "return", "select", "set_global", "set_local", "start", "table", "tee_local", "then", "type", "unreachable"];
  const FUNCTION_REFERENCE = {
    begin: [/(?:func|call|call_indirect)/, /\s+/, /\$[^\s)]+/],
    className: {
      1: "keyword",
      3: "title.function"
    }
  };
  const ARGUMENT = {
    className: "variable",
    begin: /\$[\w_]+/
  };
  const PARENS = {
    match: /(\((?!;)|\))+/,
    className: "punctuation",
    relevance: 0
  };
  const NUMBER = {
    className: "number",
    relevance: 0,
    // borrowed from Prism, TODO: split out into variants
    match: /[+-]?\b(?:\d(?:_?\d)*(?:\.\d(?:_?\d)*)?(?:[eE][+-]?\d(?:_?\d)*)?|0x[\da-fA-F](?:_?[\da-fA-F])*(?:\.[\da-fA-F](?:_?[\da-fA-D])*)?(?:[pP][+-]?\d(?:_?\d)*)?)\b|\binf\b|\bnan(?::0x[\da-fA-F](?:_?[\da-fA-D])*)?\b/
  };
  const TYPE = {
    // look-ahead prevents us from gobbling up opcodes
    match: /(i32|i64|f32|f64)(?!\.)/,
    className: "type"
  };
  const MATH_OPERATIONS = {
    className: "keyword",
    // borrowed from Prism, TODO: split out into variants
    match: /\b(f32|f64|i32|i64)(?:\.(?:abs|add|and|ceil|clz|const|convert_[su]\/i(?:32|64)|copysign|ctz|demote\/f64|div(?:_[su])?|eqz?|extend_[su]\/i32|floor|ge(?:_[su])?|gt(?:_[su])?|le(?:_[su])?|load(?:(?:8|16|32)_[su])?|lt(?:_[su])?|max|min|mul|nearest|neg?|or|popcnt|promote\/f32|reinterpret\/[fi](?:32|64)|rem_[su]|rot[lr]|shl|shr_[su]|store(?:8|16|32)?|sqrt|sub|trunc(?:_[su]\/f(?:32|64))?|wrap\/i64|xor))\b/
  };
  const OFFSET_ALIGN = {
    match: [/(?:offset|align)/, /\s*/, /=/],
    className: {
      1: "keyword",
      3: "operator"
    }
  };
  return {
    name: 'WebAssembly',
    keywords: {
      $pattern: /[\w.]+/,
      keyword: KWS
    },
    contains: [LINE_COMMENT, BLOCK_COMMENT, OFFSET_ALIGN, ARGUMENT, PARENS, FUNCTION_REFERENCE, hljs.QUOTE_STRING_MODE, TYPE, MATH_OPERATIONS, NUMBER]
  };
}
module.exports = wasm;