import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private jwtauth: JwtAuthService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const user = this.jwtauth.getUser();
    if (user) {
      this.redirectTo('/organizacoes');
      return of(false);
    }
    return of(true);
  }

  private redirectTo(path: string, queryParams = {}): void {
    this.router.navigate([path], { queryParams, queryParamsHandling: 'preserve' });
  }
}
