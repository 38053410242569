import { Component, OnInit, signal } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatChipInputEvent } from '@angular/material/chips';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  template: `
    <mat-form-field class="example-form-field">
      <mat-label>{{ props.label }}</mat-label>
      <mat-chip-grid #chipGrid aria-label="Enter keywords" [formControl]="formControl">
        @for (keyword of keywords(); track keyword) {
          <mat-chip-row (removed)="removeKeyword(keyword)">
            {{ keyword }}
            <button matChipRemove aria-label="'remove ' + keyword">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      </mat-chip-grid>
      <input
        [placeholder]="props.placeholder || 'Nova palavra...'"
        [matChipInputFor]="chipGrid"
        (matChipInputTokenEnd)="add($event)"
      />
      <mat-hint>Aperte enter para adicionar</mat-hint>
    </mat-form-field>
  `,
  styles: [`
    .chip-list {
      width: 100%;
    }
  `]
})
export class FormlyChipsComponent extends FieldType<FieldTypeConfig> implements OnInit {

  readonly keywords = signal([]);

  ngOnInit() {
    this.keywords.update(keywords => this.formControl.value);
  }

  removeKeyword(keyword: string) {
    this.keywords.update(keywords => {
      const index = keywords.indexOf(keyword);
      if (index < 0) {
        return keywords;
      }

      keywords.splice(index, 1);
      return [...keywords];
    });
    this.formControl.setValue(this.keywords());
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.keywords.update(keywords => [...keywords, value]);
      this.formControl.setValue(this.keywords());
    }

    // Clear the input value
    event.chipInput!.clear();
  }

}
