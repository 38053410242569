import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Translation } from '../../views/persons/services/person-risks.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  translation: BehaviorSubject<Translation[]> = new BehaviorSubject([]);

  constructor(
    private utilsService: UtilsService,
    private httpClient: HttpClient) {
  }

  set(translation: Translation[]) {
    this.translation.next(translation);
  }

  get(): Observable<Translation[]> {
    return this.translation.asObservable();
  }

  translate(index: string, field: string, translations: Translation[]) {
    if (index && field && translations) {
      const translation = translations?.find(
        t => t.field === this.capitalizeFirstLetter(field),
      );
      if (translation) {
        const { values } = translation;
        return values[index] || index;
      } else {
        return index;
      }
    }
    return index;
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getTranslations(queryParams: any = {}): Observable<Translation[]> {
    const params = queryParams ? this.utilsService.objectParams(queryParams) : undefined;
    return this.httpClient.get(`${ environment.apiUrl }/translations`, { params }).pipe(
      map(response => {
        const { translations = [] } = (response as any) || {};
        return translations;
      }),
    );
  }
}
