codeInput.plugins.Autodetect = class extends codeInput.Plugin {
  constructor() {
    super([]);
  }
  beforeHighlight(a) {
    let b = a.codeElement;
    b.className = "", b.parentElement.className = "";
  }
  afterHighlight(a) {
    let b = a.codeElement.className || a.preElement.className,
      c = b.match(/lang(\w|-)*/i)[0];
    c = c.split("-")[1], "undefined" == c ? (a.removeAttribute("language"), a.removeAttribute("lang")) : a.setAttribute("language", c);
  }
};