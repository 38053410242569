import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { RecipientValidationCodes } from '../models/recipient-validation-codes';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecipientValidationCodesEntityService extends BaseEntityService<RecipientValidationCodes> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'recipientValidationCode',
        plural: 'recipientValidationCodes',
        url: 'recipient-validation-codes'
      },
      routerParamsService
    );
    this.nameId = 'recipientValidationCodeId';
  }


  getRecipientValidationCodes(recipientValidationCode: RecipientValidationCodes): Observable<RecipientValidationCodes> {
    return this.add(recipientValidationCode);
  }
}
