import { Injectable } from '@angular/core';
import { FormAnswer } from '../models/form.model';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfirmService, DataConfirm } from '../../shared/services/app-confirm/confirm.service';
import { FormSubmissionsEntityService } from './form-submissions-entity.service';

@Injectable({
  providedIn: 'root',
})
export class FormAnswersEntityService extends BaseEntityService<FormAnswer> {

  constructor(
    private confirmService: ConfirmService,
    private formSubmissionsEntityService: FormSubmissionsEntityService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'formAnswer', plural: 'formAnswers', url: 'form-answers' },
      routerParamsService
    );
    this.nameId = 'formAnswersId';
  }

  getQuestionsWithAnswers(queryParams: any): Observable<unknown> {
    return this.getQuestions(queryParams).pipe(
      switchMap(formQuestions => {
        const { personIds, formSubmissionId } = queryParams;
        return this.formSubmissionsEntityService
          .getWithQuery({ personIds })
          .pipe(
            map(formSubmissions => {
              return {
                formQuestions,
                formSubmissions,
                personIds,
                formSubmissionId,
              };
            }),
          );
      }),
      switchMap(responses => {
        const { formSubmissions, formSubmissionId } = responses;
        let [lastFormSubmission] = formSubmissions;
        if (formSubmissionId) {
          lastFormSubmission = formSubmissions.find(
            fs => fs.id === formSubmissionId,
          );
        }

        if (!lastFormSubmission?.id) {
          return of(responses);
        }

        return this.getWithQuery({
          formSubmissionIds: lastFormSubmission.id.toString(),
          limit: '200',
        }).pipe(
          map(formAnswers => {
            return { ...responses, formAnswers, lastFormSubmission };
          }),
        );
      }),
    );
  }

  getQuestions(queryParams: any): Observable<unknown[]> {
    const params = queryParams;
    return this.httpClient
      .get(`${ environment.apiUrl }/form-questions`, { params })
      .pipe(map((response: any) => response?.formQuestions));
  }


  getAges(formSubmissionIds: string): Observable<FormAnswer[]> {
    const queryParams = { formSubmissionIds, formQuestionIds: '5' };
    return super.getWithQuery(queryParams).pipe(
      map((response: any) => response?.formAnswers || []),
    );
  }

  removeResponse(answer: FormAnswer) {
    const data: DataConfirm = {
      buttonConfirm: {
        label: 'Deletar',
        show: true,
      },
      title: 'Deseja realmente remover esta resposta?',
      message: 'Esta ação não pode ser revertida!',
    };
    return this.confirmService.confirm(data).pipe(
      switchMap(response => {
        if (response) {
          return this.delete(answer.id).pipe(map(() => true));
        }
        return of(false);
      }),
    );
  }
}
