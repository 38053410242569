import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { OrganizationService } from '../services/organization.service';

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private jwtauth: JwtAuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.organizationService.clearOrganization();
    this.jwtauth.logout(true);
    return false;
  }
}
