import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {

  constructor(private dateService: DateService) {
  }

  transform(value: Date) {
    // Converte a data UTC para o fuso horário local
    const dataLocal = new Date(value);

    // Obtém a data atual
    const dataAtual = new Date();

    // Calcula a diferença em milissegundos
    const diferencaEmMilissegundos = dataLocal.getTime() - dataAtual.getTime();

    // Calcula a diferença em segundos, minutos, horas, dias, meses e anos
    const segundos = Math.abs(Math.floor(diferencaEmMilissegundos / 1000));
    const minutos = Math.abs(Math.floor(segundos / 60));
    const horas = Math.abs(Math.floor(minutos / 60));
    const dias = Math.abs(Math.floor(horas / 24));
    const meses = Math.abs(Math.floor(dias / 30.436875)); // Média de dias por mês
    const anos = Math.abs(Math.floor(meses / 12));

    // Retorna a unidade de tempo mais próxima
    if (anos > 0) {
      return `${ anos } anos`;
    } else if (meses > 0) {
      return `${ meses } mês`;
    } else if (dias > 0) {
      return `${ dias } dias`;
    } else if (horas > 0) {
      return `${ horas } horas`;
    } else if (minutos > 0) {
      return `${ minutos } minutos`;
    } else {
      return `${ segundos } segundos`;
    }
  }
}
