/*
Language: Nginx config
Author: Peter Leonov <gojpeg@yandex.ru>
Contributors: Ivan Sagalaev <maniac@softwaremaniacs.org>
Category: config, web
Website: https://www.nginx.com
*/

/** @type LanguageFn */
function nginx(hljs) {
  const regex = hljs.regex;
  const VAR = {
    className: 'variable',
    variants: [{
      begin: /\$\d+/
    }, {
      begin: /\$\{\w+\}/
    }, {
      begin: regex.concat(/[$@]/, hljs.UNDERSCORE_IDENT_RE)
    }]
  };
  const LITERALS = ["on", "off", "yes", "no", "true", "false", "none", "blocked", "debug", "info", "notice", "warn", "error", "crit", "select", "break", "last", "permanent", "redirect", "kqueue", "rtsig", "epoll", "poll", "/dev/poll"];
  const DEFAULT = {
    endsWithParent: true,
    keywords: {
      $pattern: /[a-z_]{2,}|\/dev\/poll/,
      literal: LITERALS
    },
    relevance: 0,
    illegal: '=>',
    contains: [hljs.HASH_COMMENT_MODE, {
      className: 'string',
      contains: [hljs.BACKSLASH_ESCAPE, VAR],
      variants: [{
        begin: /"/,
        end: /"/
      }, {
        begin: /'/,
        end: /'/
      }]
    },
    // this swallows entire URLs to avoid detecting numbers within
    {
      begin: '([a-z]+):/',
      end: '\\s',
      endsWithParent: true,
      excludeEnd: true,
      contains: [VAR]
    }, {
      className: 'regexp',
      contains: [hljs.BACKSLASH_ESCAPE, VAR],
      variants: [{
        begin: "\\s\\^",
        end: "\\s|\\{|;",
        returnEnd: true
      },
      // regexp locations (~, ~*)
      {
        begin: "~\\*?\\s+",
        end: "\\s|\\{|;",
        returnEnd: true
      },
      // *.example.com
      {
        begin: "\\*(\\.[a-z\\-]+)+"
      },
      // sub.example.*
      {
        begin: "([a-z\\-]+\\.)+\\*"
      }]
    },
    // IP
    {
      className: 'number',
      begin: '\\b\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}(:\\d{1,5})?\\b'
    },
    // units
    {
      className: 'number',
      begin: '\\b\\d+[kKmMgGdshdwy]?\\b',
      relevance: 0
    }, VAR]
  };
  return {
    name: 'Nginx config',
    aliases: ['nginxconf'],
    contains: [hljs.HASH_COMMENT_MODE, {
      beginKeywords: "upstream location",
      end: /;|\{/,
      contains: DEFAULT.contains,
      keywords: {
        section: "upstream location"
      }
    }, {
      className: 'section',
      begin: regex.concat(hljs.UNDERSCORE_IDENT_RE + regex.lookahead(/\s+\{/)),
      relevance: 0
    }, {
      begin: regex.lookahead(hljs.UNDERSCORE_IDENT_RE + '\\s'),
      end: ';|\\{',
      contains: [{
        className: 'attribute',
        begin: hljs.UNDERSCORE_IDENT_RE,
        starts: DEFAULT
      }],
      relevance: 0
    }],
    illegal: '[^\\s\\}\\{]'
  };
}
module.exports = nginx;