<mat-drawer-container autosize class="app-container">
  <mat-drawer #sidenav [class.mat-elevation-z4]="true" [opened]="false" class="app-sidenav"
              [mode]="openMenuMode">
    <ul class="nav-drawer">
      <li (click)="sidenav.close()" class="disabled">
        <mat-icon aria-label="Menu">menu</mat-icon>
      </li>
      @for (item of menu; track $index) {
        <li *ngIf="item.type === 'item' && item.showPath" [routerLink]="item.path" [class.active]="item.active"
            class="click">
          <mat-icon [class.material-icons-full]="item.active">{{ item.icon }}</mat-icon>
          <label>{{ item.label }}</label>
        </li>
        <mat-divider *ngIf="item.type === 'divider'"/>
      }

      <span class="example-spacer"></span>

      @for (item of menuFooter; track $index) {
        <li *ngIf="item.type === 'item' && item.showPath" [routerLink]="item.path" [class.active]="item.active"
            class="click">
          <mat-icon [class.material-icons-full]="item.active">{{ item.icon }}</mat-icon>
          <label>{{ item.label }}</label>
        </li>
        <mat-divider *ngIf="item.type === 'divider'"/>
      }
    </ul>
  </mat-drawer>
  <mat-drawer-content>
    <div *ngIf="!sidenav.opened">
      <ul class="nav-rails">
        <li>
          <button (click)="sidenav.open()" mat-icon-button style="margin: 10px;">
            <mat-icon aria-label="Menu">menu</mat-icon>
          </button>
        </li>
        @for (item of menu; track $index) {
          <li  *ngIf="item.type === 'item' && item.showPath" [routerLink]="item.path" [class.active]="item.active"
               [matTooltip]="item.label" matTooltipPosition="right"
              class="click">
            <div class="icon">
              <mat-icon [class.material-icons-full]="item.active">{{ item.icon }}</mat-icon>
            </div>
            <label>{{ item.shortLabel || item.label }}</label>
          </li>
          <mat-divider *ngIf="item.type === 'divider'"/>
        }
        <span class="example-spacer"></span>
        @for (item of menuFooter; track $index) {
          <li *ngIf="item.type === 'item' && item.showPath" [routerLink]="item.path" [class.active]="item.active"
              [matTooltip]="item.label" matTooltipPosition="right"
              class="click">
            <div class="icon">
              <mat-icon [class.material-icons-full]="item.active">{{ item.icon }}</mat-icon>
            </div>
            <label>{{ item.shortLabel || item.label }}</label>
          </li>
          <mat-divider *ngIf="item.type === 'divider'"/>
        }
      </ul>
    </div>
    <div #container class="w-100 body-container toolbar">
      <div class="mobile">
        <mat-toolbar showOnSize="mobile">
          <button (click)="toggle()" mat-icon-button>
            <mat-icon>menu</mat-icon>
          </button>
          <h1>{{ title }}</h1>
        </mat-toolbar>
      </div>
      <div showOnSize="mobile" class="toolbar-ref"></div>
      <router-outlet/>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
