import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportsService } from './reports.service';

export interface ILayoutConf {
  toolbarNav?: boolean;
  menu?: Menu[];
  menuFooter?: Menu[];
  updatedAt?: number;
  menuBanned?: string[];
  titlePage?: string;
}


@Injectable({
  providedIn: 'root'
})
export class MenuLayoutService {
  public layoutConf: ILayoutConf = {
    toolbarNav: true,
  };
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  menu: Menu[] = [];
  menuFooter: Menu[] = [];

  constructor(
    private reportsService: ReportsService,
  ) {
    this.setMenu();
    this.setMenuFooter();
  }

  updateLayoutConfig() {
    this.setLayout({ updatedAt: new Date().getTime() });
  }

  setLayout(layoutConfSet: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConfSet };
    this.layoutConfSubject.next(this.layoutConf);
  }

  setMenuFooter() {
    this.menuFooter = [
      {
        type: 'divider'
      },
      {
        icon: 'logout',
        label: 'Sair',
        shortLabel: 'Sair',
        type: 'item',
        path: '/sessions/logout',
      },
    ];
    this.setLayout({ menuFooter: this.menuFooter });
  }

  setMenu() {
    this.menu = [
      // {
      //   icon: 'people',
      //   label: 'RHs',
      //   shortLabel: 'RHs',
      //   type: 'item',
      //   showPath: false,
      //   idRequired: 'organizationId',
      //   path: '/organizacoes/organizationId/rhs',
      //   acl: ['HEALTH_OPERATOR', 'BROKER']
      // },
      {
        icon: 'business',
        label: 'Clientes',
        shortLabel: 'Clientes',
        type: 'item',
        showPath: false,
        path: '/organizacoes',
        activeType: 'FULL'
      },
      {
        icon: 'corporate_fare',
        label: 'Cliente detalhes',
        shortLabel: 'Cliente detalhes',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'chat',
        label: 'Regras de notificações customizadas',
        shortLabel: 'Regras Notica...',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/regras-notificacao-customizada',
        activeType: 'FULL'
      },
      {
        icon: 'event_available',
        label: 'Ciclos',
        shortLabel: 'Ciclos',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/ciclos',
        activeType: 'FULL',
        acl: ['HUMAN_RESOURCES']
      },
      {
        icon: 'free_cancellation',
        label: 'Gratuidade de exames',
        shortLabel: 'Gratuidade',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/gratuidade-exames',
        activeType: 'FULL',
      },
      {
        icon: 'settings_suggest',
        label: 'Regra de Recomendações',
        shortLabel: 'Regra de Recomenda',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/recomendacoes',
        activeType: 'FULL',
      },
      {
        icon: 'rule_folder',
        label: 'Permissões',
        shortLabel: 'Permissões',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/permissoes',
        activeType: 'FULL',
      },
      {
        icon: 'import_export',
        label: 'Import / Export',
        shortLabel: 'Import  Export',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/import-export',
        activeType: 'FULL',
        checkParent: true,
      },
      {
        icon: 'table_rows',
        label: 'Navegações Airtable',
        shortLabel: 'Navegações Airtable',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/navegacao-airtable',
        activeType: 'FULL',
      },
      {
        icon: 'article',
        label: 'Termos',
        shortLabel: 'Termos',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/termos',
        activeType: 'FULL',
      },
      {
        icon: 'notes',
        label: 'Fila de exames',
        shortLabel: 'Fila exames',
        type: 'item',
        showPath: false,
        path: '/pessoas/exames-pendentes',
        activeType: 'PARTIAL',
        excludeByParam: 'organizationId',
      },
      {
        icon: 'warning',
        label: 'BirdId',
        shortLabel: 'BirdId',
        type: 'item',
        showPath: false,
        path: '/organizacoes/birdid',
        activeType: 'FULL',
        excludeByParam: 'organizationId',
      },
      {
        icon: 'notifications',
        label: 'Notificações',
        shortLabel: 'Notificações',
        type: 'item',
        showPath: false,
        path: '/organizacoes/notificacoes',
        activeType: 'FULL',
        excludeByParam: 'organizationId',
      },
      {
        icon: 'summarize',
        label: 'Report Template',
        shortLabel: 'Report Template',
        type: 'item',
        showPath: false,
        path: '/organizacoes/report-template',
        activeType: 'FULL',
        excludeByParam: 'organizationId',
      },
      {
        icon: 'toggle_on',
        label: 'Feature Flags',
        shortLabel: 'Feature Flags',
        type: 'item',
        showPath: false,
        path: '/organizacoes/feature-flags',
        activeType: 'FULL',
        excludeByParam: 'organizationId',
      },
    ];
    this.setLayout({ menu: this.menu });
  }

  clearAllBan(): void {
    this.layoutConf = { ...this.layoutConf, menuBanned: [] };
    this.layoutConfSubject.next(this.layoutConf);
  }

  banManu(menuName: menuPathBanned) {
    const { menuBanned = [] } = this.layoutConf;
    if (menuBanned.indexOf(menuName) === -1) {
      menuBanned.push(menuName);
    }
    this.layoutConf = { ...this.layoutConf, menuBanned };
    this.layoutConfSubject.next(this.layoutConf);
  }

  removeBanManu(menuName: menuPathBanned) {
    const { menuBanned = [] } = this.layoutConf;
    const nMenuBanned = menuBanned.filter(mb => mb !== menuName);
    this.layoutConf = { ...this.layoutConf, menuBanned: nMenuBanned };
    this.layoutConfSubject.next(this.layoutConf);
  }

  setTitle(titlePage: string) {
    this.layoutConf = { ...this.layoutConf, titlePage };
    this.layoutConfSubject.next(this.layoutConf);
  }
}

type menuPathBanned =
  '/organizacoes/organizationId/desconto-incentivado';

export interface Menu {
  path?: string;
  showPath?: boolean;
  idRequired?: string;
  excludeByParam?: string;
  checkParent?: boolean;
  acl?: acl[],
  icon?: string;
  label?: string;
  shortLabel?: string;
  active?: boolean;
  activeType?: 'PARTIAL' | 'FULL'
  type: 'item' | 'submenu' | 'divider';
}

export type acl = 'HUMAN_RESOURCES' | 'BROKER' | 'HEALTH_OPERATOR';
