import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationEntityService } from '../../state/entity-services/organization-entity.service';

@Injectable()
export class OrganizationInternalGuard implements CanActivate {
  constructor(
    private organizationEntityService: OrganizationEntityService,
    private router: Router, private jwtAuthService: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtAuthService.isLoggedIn) {
      this.router.navigate(['/sessions/login'], {
        queryParams: {
          return: state.url,
        },
      });
      return of(false);
    }

    const organizationId = this.findOrganization(route);
    if (!organizationId) {
      return of(true);
    }

    return this.organizationEntityService.getById(organizationId).pipe(
      map(response => {
        return true;
      })
    );
  }

  private findOrganization(route: ActivatedRouteSnapshot): string | undefined {
    return route?.params['organizationId'] || route?.children && this.findOrganization(route.children[0]);
  }
}


