import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  Exams,
  PerformedExam,
  PerformedExamAudEntityFormatted,
  PerformedExamAudFormatted,
  PerformedExamAuds
} from '../models/exams';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PersonExamService } from '../../views/persons/services/person-exams.service';
import { UtilsService } from '../../shared/services/utils.service';
import { DateService } from '../../shared/services/date.service';
import { PersonEntityService } from './person-entity.service';

@Injectable({
  providedIn: 'root',
})
export class PerformedExamsEntityService extends BaseEntityService<PerformedExam> {
  constructor(
    private personExamService: PersonExamService,
    private utilsService: UtilsService,
    private personEntityService: PersonEntityService,
    private dateService: DateService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'performedExam', plural: 'performedExams', url: 'performed-exams' },
      routerParamsService
    );
    this.nameId = 'performedExamId';
  }

  save(dataToSave: any): Observable<any> {
    const bodyMany = this.createManyForms(dataToSave);
    if (!!bodyMany[0]?.id) {
      const [body] = bodyMany;
      return this.update(body);
    }
    const body = { performedExams: bodyMany };
    return this.httpClient
      .post(`${ this.entityUrl }/create-many`, body)
      .pipe(map((response: any) => response?.performedExams || []));
  }

  getPerformedExamHome(
    queryParams: any,
  ): Observable<PerformedExam[]> {
    return this.httpClient
      .get([this.entityUrl].join('/'), { params: queryParams })
      .pipe(map((response: any) => response?.performedExams || []));
  }

  createManyForms(dataToSave: any): PerformedExam[] {
    const { defaultValues, formConfigs, formConfigDefault } = dataToSave;
    return formConfigs.map((formConfig: { exam: any, form: any }) => {
      const { exam, form } = formConfig;
      const { value: formConfigDefaultValues } = formConfigDefault.form;
      const { value } = form;

      const nDateToSave = {
        ...defaultValues,
        ...formConfigDefaultValues,
        ...value,
      };
      Object.keys(nDateToSave).forEach(key => {
        if (!nDateToSave[key]) {
          delete nDateToSave[key];
        }
      });

      const { expectedExamResults } = exam;
      const examResults = [];
      expectedExamResults.map((expectedExamResult: { name: string, id: number }) => {
        const { name, id } = expectedExamResult;
        if (nDateToSave[name]) {
          examResults.push({
            value: nDateToSave[name],
            expectedExamResult: {
              id,
            },
          });
        }
        delete nDateToSave[name];
      });

      const {
        performedAt,
        performedExamId,
        report,
        documentUrl,
        laboratory = null,
        examParseId,
      } = nDateToSave;

      console.log(
        this.dateService.format(nDateToSave[`performedAt${ exam.id }`], 'dateLocal', true, 'date'),
        this.dateService.format(performedAt, 'dateLocal', true, 'date')
      );
      console.log(nDateToSave[`performedAt${ exam.id }`], performedAt);
      console.log(nDateToSave[`performedAt${ exam.id }`], performedAt);

      const performedAtRight = nDateToSave[`performedAt${ exam.id }`] ?? performedAt;
      const reportRight = nDateToSave[`report${ exam.id }`] ?? report;
      return {
        ...nDateToSave,
        report: reportRight,
        performedAt: this.dateService.format(performedAtRight, 'dateLocal', true, 'date'),
        exam: { id: exam.id },
        examParse: { id: examParseId },
        examResults,
        laboratory,
        id: performedExamId || null,
        documentUrl: documentUrl || null,
        source: 'MANUAL',
      };
    });
  }

  remove(key: any) {
    return this.delete(key);
  }

  audsRequest(queryParams: any): Observable<PerformedExamAuds[]> {
    return this.httpClient
      .get([environment.apiUrl, 'performed-exam-auds'].join('/'), { params: queryParams })
      .pipe(map((response: any) => response?.performedExamAuds));
  }

  auds(queryParams: any): Observable<PerformedExamAudFormatted[]> {
    return this.audsRequest(queryParams).pipe(
      switchMap(response => {
        const ids = [
          ...new Set(
            response.map(r => r.revision.person?.id).filter(id => !!id),
          ),
        ];
        return this.personEntityService
          .getWithQuery({ ids: ids.join(',') })
          .pipe(
            map(persons => {
              return response.map(r => {
                r.revision.person = persons.find(
                  p => p.id === r.revision.person.id,
                );
                return r;
              });
            }),
          );
      }),
      switchMap(response => {
        const ids = [
          ...new Set(response.map(r => r.entity.exam?.id).filter(id => !!id)),
        ];
        return this.getExams({ ids: ids.join(',') }).pipe(
          map(exams => {
            return response.map(r => {
              r.entity.exam = exams.find(e => e.id === r.entity?.exam?.id);
              return r;
            });
          }),
        );
      }),
      map(response => {
        return this.createListAud(response);
      }),
    );
  }

  getExams(queryParams: any): Observable<Exams[]> {
    const params = this.utilsService.objectParams(queryParams);
    return this.httpClient
      .get([environment.apiUrl, 'exams'].join('/'), { params })
      .pipe(map((response: any) => response?.exams || []));
  }

  createListAud(
    performedExamAuds: PerformedExamAuds[],
  ): PerformedExamAudFormatted[] {
    // Enrich with previous value. INSERT types won`t have previous value.
    performedExamAuds.forEach((aud, index) => {
      const olderAuds = performedExamAuds.slice(index + 1);
      const olderAudSameId = olderAuds.find(
        olderAud => olderAud.entity.id === aud.entity.id,
      );
      aud.previousEntity = olderAudSameId?.entity;
    });

    return performedExamAuds.map(aud => this.audFormat(aud));
  }

  audFormat(performedExamAud: PerformedExamAuds): PerformedExamAudFormatted {
    const { entity, previousEntity, revision, type } = performedExamAud;
    return {
      examName: entity.exam ? entity.exam.name : previousEntity.exam.name,
      revisionPersonName: revision?.person?.personProperties?.name,
      revisionTimestamp: this.dateService.formatPipe(
        revision.timestamp,
        'DD/MM/YYYY HH:mm:ss',
      ),
      type,
      old: previousEntity ? this.audFormatEntity(previousEntity) : null,
      current: this.audFormatEntity(entity),
    };
  }

  audFormatEntity(entity: PerformedExam): PerformedExamAudEntityFormatted {
    const { performedAt, documentUrl, requestedBy, examResults, exam, report } =
      entity;
    const { expectedExamResults } = exam || {};
    return {
      report,
      performedAt: performedAt
        ? this.dateService.formatPipe(performedAt, 'DD/MM/YYYY')
        : '',
      documentUrl,
      requestedBy: requestedBy
        ? this.utilsService.stringFormat(requestedBy)
        : '',
      results: examResults?.map(er => {
        const expectedResultId = er.expectedExamResult.id;
        er.expectedExamResult = expectedExamResults.find(
          eer2 => eer2.id === expectedResultId,
        );
        return `${
          er.expectedExamResult.name
        }: ${ this.personExamService.formatExamResults(er) }`;
      }),
    };
  }
}
