import { Injectable } from '@angular/core';
import numeral from 'numeral';

@Injectable({ providedIn: 'root' })
export class NumeralService {
  constructor() {
    numeral.register('locale', 'br', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: number => {
        return number === 1 ? 'er' : 'ème';
      },
      currency: {
        symbol: 'R$',
      },
    });

    numeral.locale('br');
  }

  public formatMoney(value) {
    return numeral(value).format('0,0.00');
  }

  public format(value, mask) {
    return numeral(value).format(mask);
  }

  public getNumeral(number, format, decimal = 0) {
    if (!number) {
      return number;
    }
    if (format === '0%') {
      return `${ parseFloat(number).toFixed(decimal) }%`;
    }
    return numeral(number).format(format);
  }

  public formatMoneyCurrency(balance) {
    return this.getNumeral(balance / 100, '0,0.00');
  }

  public centsToCurrency(balance) {
    const numeralFormated = this.getNumeral(balance / 100, '0,0.00');
    return numeralFormated;
  }

  public formatMoneyFromDb(value) {
    return this.formatMoney(value / 100);
  }

  /**
   * Format a number forcing a format or not
   * @param number the number to format
   * @param decimal the number of decimals
   * @param format the expected format, if you want to force it
   */
  public getNumeralAutoFormatted(number, format = ''): string {
    const numberFormatted = number.toFixed(number % 1 === 0 ? 0 : 1);
    if (format === '0%') {
      return `${ parseFloat(numberFormatted).toFixed(
        numberFormatted % 1 === 0 ? 0 : 1,
      ) }%`;
    }
    // tslint:disable-next-line:triple-equals
    if (format != '') {
      return numeral(numberFormatted).format(format);
    }
    return numberFormatted;
  }
}
