import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsService } from '../../../shared/services/utils.service';
import { ImageCropConfig, ImageCropService } from '../../../shared/services/image-crop.service';


@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit {
  @Output() callback = new EventEmitter();
  @Input() imageCroped: boolean;
  @Input() config: ImageCropConfig;
  @Input() prefix:
    | 'LEGAL_TERM'
    | 'ORGANIZATION_LOGO'
    | 'ORGANIZATION_FAVICON'
    | 'PERFORMED_EXAM'
    | 'REFERRAL'
    | 'EXAM_REQUEST'
    | 'PATIENTS'
    | 'PATIENT_FILE';
  @Input() showRemoveButton: boolean;
  @Input() titleButton = 'Upload de arquivo';
  public files: NgxFileDropEntry[] = [];

  @Input() label = '';

  imageCropedSrc: any;
  loading: boolean;

  constructor(
    private imageCropService: ImageCropService,
    private utilsService: UtilsService,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    if (this.showRemoveButton) {
      this.titleButton = 'Arquivo enviado!';
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.imageCroped) {
            this.imageCropService.openImageCropDialog(file, this.config)
              .pipe(
                switchMap(file => {
                  if (!file) {
                    return of('');
                  }
                  const { type: contentType } = file;
                  return this.sendFiles({ contentType, file });
                }),
              )
              .subscribe({
                error: (err) => {
                  this.utilsService.setErrorToast(err);
                },
              });
          } else {
            const { type: contentType } = file;
            this.sendFiles({ contentType, file }).subscribe({
              error: (err) => {
                this.utilsService.setErrorToast(err);
              },
            });
          }
        });
      }
    }
  }

  sendFiles(files: any): Observable<any> {
    this.setLoading(true);
    const { contentType, file } = files;
    return this.http
      .post(`${ environment.apiUrl }/files`, {
        file: {
          prefix: this.prefix,
          contentType,
        },
      })
      .pipe(
        switchMap(response => {
          const { file: fileResp } = response as {
            file: {
              signedPutUrl: string;
              url: string;
              signedGetUrl: string;
            };
          };
          return this.http.put(`${ fileResp.signedPutUrl }`, file).pipe(
            map(() => {
              return fileResp;
            }),
          );
        }),
        tap(response => {
          const { url, signedGetUrl } = response;
          const { name } = file;
          this.setToDefaultSend();
          this.callback.emit({
            name,
            url,
            signedGetUrl,
          });
          this.imageCropedSrc = signedGetUrl;
        }),
        catchError(err => {
          this.setToDefault();
          const { message = 'Erro ao enviar arquivo' } = err;
          this.utilsService.toast(message, 'Ok');
          throw err;
        }),
      );
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  public removeFile() {
    this.imageCropedSrc = undefined;
    this.callback.emit({ url: null });
    this.setToDefault();
  }

  private setToDefaultSend() {
    this.titleButton = 'Arquivo enviado!';
    this.showRemoveButton = true;
    this.setLoading(false);
  }

  private setToDefault() {
    this.titleButton = 'Upload de arquivo';
    this.showRemoveButton = false;
    this.setLoading(false);
  }
}
