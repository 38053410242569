import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';

// ONLY FOR DEMO
// ALWAYS REQUIRED
import { ConfirmComponent } from '../services/app-confirm/confirm.component';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { FormsModule } from '@angular/forms';
import { FormModule } from '../../form/form.module';
import { ListComponent } from './list/list.component';
import { ListTextComponent } from './list/list-text.component';
import { ButtonComponent } from './button/button.component';
import { MenuLayoutComponent } from './layouts/menu-layout/menu-layout.component';
import { SharedDirectivesModule } from '../directives/shared.directives.module';
import { SharedImageViewerComponent } from './shared-image-viewer/shared-image-viewer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

const components = [
  SharedImageViewerComponent,
  ButtonComponent,
  ListComponent,
  ListTextComponent,
  AuthLayoutComponent,
  ConfirmComponent,
  MenuLayoutComponent
];

@NgModule({
  imports: [
    DragDropModule,
    SharedDirectivesModule,
    CommonModule,
    FormModule,
    RouterModule,
    SharedMaterialModule,
    FormsModule,
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule {
}
