import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  personList(): string {
    return '/pessoas';
  }

  personDetailsBase(id: unknown) {
    return [environment.professional, 'pessoas', id].join('/');
  }

  examsParses() {
    return [this.personList(), 'exames-pendentes'].join('/');
  }

  examsParsesDetail(id: unknown) {
    return [this.examsParses(), 'detalhes', id].join('/');
  }

  organizationsFeatureFlags(): string {
    return ['/organizacoes/feature-flags/detalhes'].join('/');
  }
  organizationsFeatureFlagDetail(id: unknown): string {
    return [this.organizationsFeatureFlags(), id].join('/');
  }


}
