import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamRequest, Exams } from '../models/exams';
import { DateService } from '../../shared/services/date.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { RouterParamsService } from '../../shared/services/router-params.service';

@Injectable({
  providedIn: 'root',
})
export class ExamRequestsEntityService extends BaseEntityService<ExamRequest> {

  constructor(
    private dateService: DateService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'examRequest', plural: 'examRequests', url: 'exam-requests' },
      routerParamsService
    );
    this.nameId = 'examRequestId';
  }


  save(dataToSave, exams: Exams[]): Observable<ExamRequest> {
    const nDateToSave = { ...dataToSave };
    Object.keys(nDateToSave).forEach(key => {
      if (!nDateToSave[key]) {
        delete nDateToSave[key];
      }
    });
    const { checkedManual, examId, performed, requestedAt } = nDateToSave;
    const body = {
      ...nDateToSave,
      ...(!checkedManual
        ? { documentUrl: undefined, checkedManual: undefined }
        : { checkedManual: undefined }),
      performed: performed || false,
      requestedAt: checkedManual
        ? this.dateService.formatPipe(requestedAt, 'YYYY-MM-DD')
        : this.dateService.formatPipe(new Date(), 'YYYY-MM-DD'),
      exams: exams.map(e => {
        return { id: e.id };
      }),
      id: examId || null,
    };
    if (body.id) {
      return this.update(body);
    }
    return this.add(body);
  }

  autoGenerate(selfieConsolidatedId: number): Observable<any> {
    const body = { selfieConsolidated: { id: selfieConsolidatedId } };
    return this.httpClient
      .post(`${ this.entityUrl }/auto-generate`, body)
      .pipe(map((response: any) => response?.autoGenerate));
  }
}
