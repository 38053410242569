import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'shared-image-viewer-dialog',
  styleUrl: './shared-image-viewer-dialog.component.scss',
  template: `
    <shared-image-viewer [imageUrl]="data.imageUrl"></shared-image-viewer>
    <div mat-dialog-actions>
      <button mat-button (click)="onClose()">Fechar</button>
    </div>
  `
})
export class SharedImageViewerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SharedImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
