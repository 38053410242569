import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ExamParses } from '../models/exams';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExamParsesEntityService extends BaseEntityService<ExamParses> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'examParse', plural: 'examParses', url: 'exam-parses' },
      routerParamsService
    );
    this.nameId = 'examParseId';
  }

  getCurrentFirstAvailable(id: any): Observable<ExamParses> {
    return this.getEntities$.pipe(
      switchMap(entities => {
        id = parseInt(id, 10);
        const entity = entities.find(e => e.id === id);
        if (!!entity) {
          return of(entity);
        }
        return this.getWithQuery({ ids: id.toString() }).pipe(
          map(response => {
            const [examParse] = response;
            return examParse;
          }),
        );
      }),
    );
  }

  mergeExamParses(examParses: { id: number }[]) {
    const body: any = { examParses };
    return this.httpClient
      .post([this.entityUrl, 'merge'].join('/'), body)
      .pipe(map((response: any) => response?.exameParses));
  }
}
